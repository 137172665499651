<template>
	<div class="services" v-if="filteredItems.length" ref="services">
		<div class="services__tabs">
			<button class="services__tab" :class="{'is-active': activeTab === 0}" @click="toggleTab(0)">
				Все
			</button>
			<button
				v-for="(item, index) in tabs.names"
				:key="index"
				class="services__tab"
				:class="{'is-active': tabs.ids[index] === activeTab}"
				@click="toggleTab(tabs.ids[index])"
			>
				<img src="" alt="" class="services__icon" />
				{{ item }}
			</button>
		</div>
		<div class="services__groups">
			<div v-for="(tabId, index) in tabs.ids">
				<div class="services__body" :class="{active: tabId === activeTab || activeTab === 0}">
					<div class="services__body-title">
						{{ tabs.names[index] }}
					</div>
					<div class="services__list">
						<div v-for="item in tabItems(tabId)" :key="item.id" class="services__item"
							 @click.stop="openModal(item)">
							<span class="services__name">{{ item.name }}</span>
							<span class="services__text">{{ item.annotation }}</span>
							<span class="services__footer">
							<span class="services__price">
								<span class="services__price-label">Цена: </span>
								<span>{{ item.price.basePrice | formatPrice }} ₽
								</span>
							</span>
							<span class="services__age" v-if="item.minAge && item.minAge >= 18">18+</span>
						</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="opened" class="services__wrapper">
			<div class="services__modal" v-click-outside="closeServiceDiscount">
				<div class="services__modal-header">
					<div class="services__modal-image">
						<img :src="item.mainImage" alt="" />
					</div>
					<div class="services__modal-info">
						<p class="services__modal-title">{{ item.name }}</p>
						<p class="services__price">
							Стоимость за человека<br />
							{{ item.price.basePrice | formatPrice }} ₽
						</p>
					</div>
					<button class="services__modal-close" @click="closeServiceDiscount">
						<svg data-v-68cdcfc3="" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g data-v-68cdcfc3="" clip-path="url(#clip0_437_16670)"><path data-v-68cdcfc3="" d="M6.26264 8L0 1.7775L1.78896 0L9.84056 8L1.78896 16L0 14.2225L6.26264 8Z" fill="#9A243D"></path> <path data-v-68cdcfc3="" d="M9.73736 8L16 1.7775L14.211 0L6.15944 8L14.211 16L16 14.2225L9.73736 8Z" fill="#9A243D"></path></g> <defs data-v-68cdcfc3=""><clipPath data-v-68cdcfc3="" id="clip0_437_16670"><rect data-v-68cdcfc3="" width="16" height="16" fill="white"></rect></clipPath></defs></svg>
					</button>
				</div>
				<div class="services__modal-body">
					<div class="services__modal-text" v-html="item.description"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import crsCruise from "../../rest/crs/crsCruise";
import ClickOutside from 'vue-click-outside';

export default {
	props: {
		cruiseId: Number
	},
	directives: {
		ClickOutside
	},
	data() {
		return {
			activeTab: 0,
			items: [],
			opened: false,
			item: null,
			inited: false
		};
	},
	computed: {
		filteredItems() {
			return this.items.filter(item => {
				if (~item.name.toLowerCase().indexOf('commission') || ~item.name.toLowerCase().indexOf('fee')) {
					return false
				} else {
					return true
				}
			}).filter(item => !!item.price.basePrice)
		},
		tabs() {
			const names = [];
			const ids = [];

			this.filteredItems.forEach(element => {
				names.push(element.type.name);
				ids.push(element.type.id);
			});

			const filterNames = new Set(names);
			const filterIds = new Set(ids);

			return {
				names: [...filterNames],
				ids: [...filterIds]
			};
		}
	},
	mounted() {
    	crsCruise.bookingAdds(this.cruiseId, {with: 'price,type'})
			.then(res => {
				this.items = res.data.data;
				this.inited = true
			});
	},
	methods: {
		toggleTab(id) {
			this.activeTab = id;
		},
		openModal(item) {
			this.opened = true;
			this.item = item;
		},
		tabItems(tabId) {
			if (tabId) {
				return this.filteredItems.filter(item => item.type.id == tabId);
			} else {
				return this.filteredItems;
			}
		},
		closeServiceDiscount() {
			this.opened = false
		}
	},
	watch: {
		opened(nv) {
			const html = document.querySelector('html');
			if (nv) {
				const body = document.querySelector('body');
				body.style.paddingRight = `${window.innerWidth - document.querySelector('main').offsetWidth}px`;
				html.style.overflow = 'hidden';
			} else {
				html.style.overflow = 'auto';
				const body = document.querySelector('body');
				body.style.paddingRight = 0;
			}

		},
		filteredItems(nv) {
			const parentContentBlock = this.$el?.parentElement.closest('.d-p-tabs__content');
			if (parentContentBlock) {
				parentContentBlock.style.display = nv.length ? 'block' : 'none';
				const dataTab = parentContentBlock.getAttribute('data-tab-body');
				if (dataTab) {
					const tab = document.querySelector(`[data-tab-btn="${dataTab}"]`)
					if (tab) {
						tab.style.display = nv.length ? 'flex' : 'none';
					}
				}
			}

		}
	}
};
</script>

<style lang="scss" scoped>
.services {
	color: #171717;

	&__body {
		display: none;

		&.active {
			display: block;
		}

		&-title {
			font-weight: 500;
			font-size: 20px;
			line-height: 24px;
			color: #004155;
			margin-bottom: 20px;
		}
	}

	&__groups {
		gap: 40px;
		display: flex;
		flex-direction: column;
		gap: 40px;
	}

	&__list {
		display: flex;
		flex-direction: column;
		gap: 20px;

		@media (min-width: 768px) {
			flex-direction: row;
			flex-wrap: wrap;
		}
	}

	&__item {
		border: 1px solid #E7E7E7;
		border-radius: 2px;
		padding: 19px 24px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		cursor: pointer;

		@media (min-width: 768px) {
			width: calc(100% / 2 - 20px / 2);
		}

		@media (min-width: 1024px) {
			width: calc(100% / 3 - 20px * 2 / 3);
		}

		@media (min-width: 1440px) {
			width: calc(100% / 4 - 20px * 3 / 4);
		}

		&:hover {
			background-color: #f1f1f1;
		}

		&:active {
			background-color: #f1f1f1;
			transform: translateY(1px);
		}
	}

	&__tabs {
		display: flex;
		flex-direction: row;
		gap: 16px;
		flex-wrap: wrap;
		overflow: visible;
		margin-bottom: 24px;

		&--print {
			display: none;
		}
	}

	&__name {
		font-weight: 700;
		font-size: 18px;
		line-height: 20px;
		text-transform: none;
		color: #004155;
	}

	&__price {
		font-weight: 700;
		font-size: 16px;
		line-height: 20px;
		display: flex;
		gap: 12px;
		color: #001B23;
		margin: 0;

		&-label {
			color: #001B23;
			font-weight: 500;
		}
	}

	&__age {
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: #001B23;
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	&__text {
		font-size: 15px;
		line-height: 20px;
		color: #171717;
	}

	&__title {
		font-weight: bold;
		font-size: 26px;
		color: #000000;
		margin: 0;
	}

	&__header {
		margin-bottom: 35px;
	}

	&__tab {
		width: max-content;
		padding: 8px 16px;
		font-weight: 400;
		font-size: 15px;
		line-height: 20px;
		color: #001B23;
		background: #DEEFF0;
		margin: 0 !important;
		border-radius: 2px;

		&:active {
			color: #E9AC60;
			background: #004155;
		}

		&.is-active {
			color: #E9AC60;
			background: #004155;
		}
	}

	&__more {
		font-size: 16px;
		line-height: 150%;
		color: #9a243d;
		position: relative;

		&::after {
			content: '';
			display: block;
			width: 10px;
			height: 6px;
			position: absolute;
			right: -20px;
			top: 11px;
			background: url(/local/client/img/red-arrow-up.svg);
			background-repeat: no-repeat;
			background-position: center;
			transform: rotate(90deg);
		}
	}

	&__wrapper {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 0, 0, 0.4);
		z-index: 106;
	}

	&__modal {
		width: 583px;
		background-color: #ffffff;
		max-height: 100vh;
		overflow-y: auto;
		max-width: calc(100% - 32px);

		&-header {
			background-color: #f7faff;
			display: flex;
			position: relative;
			flex-direction: column;
			padding: 42px 16px 16px 16px;
			gap: 16px;

			@media (min-width: 768px) {
				flex-direction: row;
				gap: 24px;
			}
		}

		&-body {
			padding: 16px;
		}

		&-text {
			font-size: 16px;
			color: #171717;
			margin: 0;

			ul {
				margin-left: 16px;
			}
		}

		&-info {
			flex-grow: 1;
		}

		&-title {
			font-size: 20px;
			color: #0c2241;
			margin: 0;
			margin-bottom: 11px;
		}

		&-close {
			width: 16px;
			height: 16px;
			cursor: pointer;
			position: absolute;
			right: 16px;
			top: 16px;
		}

		&-image {
			width: 100%;
			height: 211px;
			flex-shrink: 0;

			@media (min-width: 768px) {
				width: 211px;
			}

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	@media print {
		&__tabs {
			display: none;
		}
		&__tabs--print {
			display: block;
		}
		&__body {
			display: block;
		}
		&__body + &__tabs--print {
			margin-top: 40px;
		}
	}
}
</style>
